@import "../assets/scss/global/variables";
@import "../assets/scss/mixins/";

.title {
  @include fluid-type(2rem, 3rem, 30rem, 80rem);
  color: $color-blue-dark;
  margin: 0;
}

.howTo {
  padding: 1rem;
  background-color: $color-eggy;
  margin: 1rem 0;
  border-top: $color-blue solid 0.5em;
}
.howToTitle {
  color: $color-blue-dark;
}
.steps {
  font-size: smaller;
  list-style: none;
  padding: 0;
  display: grid;
  gap: 1rem;
  @media (min-width: 40rem) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.stepImage {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 1rem;
  border-radius: 0.25em;
}
.rulesLink {
  background-color: $color-green;
  color: white;
  text-transform: uppercase;
  padding: 0.25em 0.5em;
}
