@import "../assets/scss/global/variables";
@import "../assets/scss/mixins";
@import "../assets/scss/modules/buttons";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: rgba(white, 0.8);
  animation: burst 1s forwards;
  @include padder;
  overflow-y: auto;
}

.message {
  width: 100%;
  @include padder;
  max-width: 50rem;
  background-color: white;
  border: solid 0.5rem $color-blue;
  border-radius: 1rem;
  box-shadow: 0 0.2rem 0.4rem 0.2em rgba(0, 0, 0, 0.2);
  line-height: 1.6;
}
