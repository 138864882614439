@import "../assets/scss/mixins/padder";
@import "../assets/scss/modules/buttons";

.wrapper {
  @include padder;
  border: solid 0.5rem $color-green-dark;
  border-radius: 1rem;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content {
  flex-grow: 1;
  margin-bottom: 1rem;
  line-height: 1.6;
  text-align: center;
}
.title {
  margin: 0;
  font-size: xx-large;
}
.description {
  padding: 0.5rem 0;
}
