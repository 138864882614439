@import "../assets/scss/mixins";
@import "../assets/scss/global/variables";

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  font-weight: bold;
  text-transform: uppercase;
  @include fluid-type(0.8rem, 1.2rem, 30rem, 80rem);
}
.link {
  display: block;
  color: $color-blue-dark;
  text-decoration: none;
  line-height: 0.9;
  padding: 0.5em 0.75em;
  border-radius: 0.2em;
  white-space: nowrap;
}
.current {
  color: white;
  background-color: $color-blue;
}
