@import "../assets/scss/global/variables";
@import "../assets/scss/modules/buttons";
@import "../assets/scss/modules/forms";

.ratings {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  button {
    font-size: 3rem;
    line-height: 1;
    border: none;
    border-radius: 50%;

    img {
      width: 100%;
      height: auto;
    }
  }
}
.rating {
  background-color: transparent;
  padding: 1.5rem;
  filter: grayscale(1);
}
.userRating {
  border: none;
  background-color: $color-eggy;
  padding: 0;
}

.button:disabled {
  background-color: silver;
}

.button1 {
  @extend .button;
  background-color: $color-red;
}

.button2 {
  @extend .button;
  background-color: $color-amber;
}
