@import "../global/variables";

.button,
a.button {
  border: none;
  font-size: 1em;
  padding: 1em 2em;
  line-height: 1;
  background-color: $color-green;
  color: white;
  font-weight: bold;
  border-radius: 0.25em;
  text-decoration: none;
  display: block;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2ch;
  cursor: pointer;
}
