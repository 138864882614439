@import "../assets/scss/global/variables";

.wrapper {
  background-color: $color-eggy;
  padding: 1rem;
  border-bottom: solid $color-blue 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  box-shadow: 0 0.05rem 0.75rem 0.2rem rgb(0 0 0 / 50%);
}
.logo {
  display: block;
  height: auto;
  height: 3.5rem;
  max-width: 40vw;
  margin: auto;
}
