@import "../global/variables";
@import "../mixins";
@import "buttons";
.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.label {
  display: block;
  font-weight: bold;
  flex-basis: 50%;
  margin-bottom: 0.5em;
}
.input {
  border: solid $color-blue-dark;
  font-size: 1em;
  padding: 0.5em;
  background-color: $color-eggy;
  &:focus {
    background-color: white;
  }
}
.button {
  width: 100%;
}
