@import "../assets/scss/templates/page";

.content {
  max-width: 90rem;
}
@media (min-width: 60em) {
  .content {
    display: grid;
    @include gutter;
    grid-template-columns: 1fr 1fr;
  }
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.title {
  margin: 0;
}
