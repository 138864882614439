// traffic light colours

$color-red: #f15929;
$color-amber: #fbb03f;
$color-green: #7bc45f;
$color-green-med: #588c44;
$color-green-dark: #476c39;
$color-blue: #2d9be0;
$color-blue-dark: #27719f;
$color-pink: #f1c5b7;
$color-eggy: #f9f3ea;
$color-beige: #f8e0bd;
