@import "../assets/scss/mixins/";
@import "../assets/scss/global/variables";
.wrapper {
  @include padder;
  background-color: $color-red;
  color: white;
  text-align: center;
  a {
    color: currentColor;
  }
}
.content {
  margin: auto;
  max-width: 50rem;
}
