$smaller: 1.2rem;
$bigger: 2.4rem;

@mixin padder($axis: both) {
  @if $axis == both {
    padding: $smaller;
    @media (min-width: 60rem) {
      padding: $bigger;
    }
  } @else if $axis == h {
    padding-left: $smaller;
    padding-right: $smaller;
    @media (min-width: 60rem) {
      padding-left: $bigger;
      padding-right: $bigger;
    }
  } @else if $axis == v {
    padding-top: $smaller;
    padding-bottom: $smaller;
    @media (min-width: 60rem) {
      padding-top: $bigger;
      padding-bottom: $bigger;
    }
  }
}

@mixin gutter {
  gap: $smaller;
  @media (min-width: 60rem) {
    gap: $bigger;
  }
}
