@import "../assets/scss/global/variables";
@import "../assets/scss/mixins/";
.wrapper {
  background-color: $color-blue-dark;
  @include padder;
  color: white;
}
.logos {
  display: flex;
  flex-wrap: wrap;
  @include gutter;
  margin: auto;
  justify-content: center;
  max-width: 100%;
}

.logo {
  height: 4rem;
}
