@import "../assets/scss/mixins/";
@import "../assets/scss/modules/forms";
@import "../assets/scss/modules/buttons";
.game {
  position: relative;
  height: calc(100vh - 6rem);
  overflow: hidden;
  display: grid;
  grid-template-rows: 6rem 1fr 6rem;
  box-shadow: inset 0 -10vw 20vw 10vw rgba(241, 89, 41, 0.3);
}

.scores {
  display: block;
  margin: 0;
  padding: 1rem;
  background-color: $color-eggy;
  svg {
    width: 100%;
    height: 4rem;
    display: block;
    margin: 0;
  }
}

.draw {
  grid-row: 1;
  grid-column: 1/-1;
  text-align: center;
  height: 6rem;
}

.cardsWrapper {
  padding: 2rem;
  height: calc(100vh - 18rem);
}
.cards {
  width: 100%;
  max-width: 50rem;
  margin: auto;
  display: grid;
  height: 100%;
  grid-template-columns: 2fr 1fr;
}

.card {
  display: block;
  max-height: 100%;
  width: 100%;
  max-height: 100%;

  &:first-child {
    transform: rotate(-2deg);
  }
  &:last-child {
    transform: rotate(4deg);
  }
}

.controls {
  inset: 0 0 auto auto;
  position: absolute;
  padding: 1rem;
}

.button1 {
  @extend .button;
  background-color: $color-red;
}

.button2 {
  @extend .button;
  background-color: $color-amber;
}
