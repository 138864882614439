@import "../global/variables";
@import "../mixins/";

.wrapper {
  @include padder;
}

.content {
  background-color: white;
  @include padder;
  margin: auto;
  max-width: 80ch;
  line-height: 1.6;
  border: solid thick $color-blue;
  border-radius: 1em;
  box-shadow: 0 0.1rem 0.5rem 0.25rem rgba(black, 0.5);

  h1 {
    color: $color-red;
    @include fluid-type(2em, 3em, 20em, 80em);
  }

  h2 {
    color: $color-green-med;
    @include fluid-type(1.5em, 2em, 20em, 80em);
  }

  p {
    margin: 0;
  }
  p + p {
    margin-top: 1.5em;
  }
}

.intro {
  font-weight: bold;
  font-size: 1.3rem;
}
.image {
  max-width: 100%;
  display: block;
  border-radius: 0.25em;
  margin: 1rem 0;
  &:first-child {
    margin-top: 0;
  }
}

.ctaWrapper {
  @include padder(v);
}
