@import "../assets/scss/mixins";
@import "../assets/scss/global/variables";

.wrapper {
  @include padder;
  border-top: solid 0.5rem $color-blue;
  background-color: $color-eggy;
}
.title {
  @include fluid-type(2.5rem, 3rem, 30rem, 80rem);
  text-align: center;
  margin-bottom: 2rem;
  color: $color-blue-dark;
}
.list {
  list-style: none;
  display: grid;
  padding: 0;
  margin: 0 auto;
  max-width: 90rem;
  @include gutter;
  @media (min-width: 50em) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 80em) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
