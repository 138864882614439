@import "fonts";

h1,
h2,
h3 {
  font-family: $font-header;
  line-height: 1;
  text-transform: uppercase;
  font-weight: normal;
  // text-wrap: balance;

  &:first-child {
    margin-top: 0;
  }
}
h2,
h3 {
  margin-bottom: 0.5rem;
}
