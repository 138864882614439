@import "./global/variables";
@import "./mixins/fluid-type";
@import "./global/typography";
@import "./global/accessibility";

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  min-height: 100vh;
  font-family: $font-body;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-image: url("../img/gingham.svg");
  background-size: 3em;
  background-position: center;
  background-attachment: fixed;

  @include fluid-type(1em, 1.2em, 20em, 80em);
}

::selection {
  background-color: rgba($color-amber, 0.5);
}

a {
  color: $color-blue-dark;
}
