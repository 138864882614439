@import "../assets/scss/mixins";
@import "../assets/scss/global/variables";
@import "../assets/scss/modules/buttons";
.wrapper {
  display: flex;
  flex-direction: column;
  @include gutter;
  text-align: center;
}

.title {
  @include fluid-type(1.8em, 2em, 30rem, 60rem);
  margin: 0;
  color: $color-blue-dark;
}
.details {
  margin-top: 1rem;
  line-height: 1.6;
}

.players {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: stretch;
  max-width: 25em;
  margin: auto;
}

.playerOne,
.playerTwo {
  display: block;
  width: 100%;
  height: auto;
  max-height: 12em;
}
.trafficLight {
  display: block;
  width: 4rem;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 0;
}

.didYouKnow {
  background-color: $color-eggy;
  border-radius: 0.25em;
  overflow: hidden;
}

.didYouKnowTitle {
  margin: 0;
  background-color: $color-blue;
  color: white;
  @include fluid-type(1.2em, 1.5em, 30rem, 60rem);
  padding: 0.5rem;
}
.didYouKnowContent {
  padding: 0.5rem;
  line-height: 1.6;
  font-size: smaller;
}

.button {
  width: 100%;
}
