@import "../assets/scss/templates/page";
.cards {
  list-style: none;
  padding: 0;
  @include padder(v);
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
}
.card {
  width: 100%;
  height: auto;
}
